.stamp_m {
    position: relative;
    display: inline-flex;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    color: red;
    padding: 15px;
    font-weight: 900;
    font-size: 18px;
    background-color: white;
    box-shadow: inset 0px 0px 0px 6px red, inset 0px 0px 0px 9px white,
      inset 0px 0px 0px 12px red;
    transform: rotate(-30deg);
    align-items: center;
    justify-content: center;
  }
  
  .stamp_m::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url("../../assets/img/noise.jpg");
  
    mix-blend-mode: lighten;
  }
  
  .void_stamp {
    position: relative;
    display: inline-flex;
    width: 360px;
    height: 120px;
    
    color: red;
    padding: 15px;
    font-weight: 900;
    font-size: 32px;
    
    box-shadow: inset 0px 0px 0px 6px red, inset 0px 0px 0px 9px white,
      inset 0px 0px 0px 12px red;
    transform: rotate(-30deg) translateY(-200px);
    align-items: center;
    justify-content: center;
  }
  
  .void_stamp::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*background-image: url("../../assets/img/noise.jpg");*/
  
    mix-blend-mode: lighten;
  }